<template>
	<span>
		<a-spin :spinning="loading">
			<span @click="onShowModal">
				<slot>
					<a-button type="primary">选择卖品</a-button>
					<span> 已选{{selectedRowKeys.length}}件商品</span>
				</slot>
			</span>

			<a-modal v-model:visible="showSnackModal" title="选择卖品" destroyOnClose width="880px" @cancel="onCancel" @ok="onOK">
				<a-spin :spinning="loading">
					<a-form ref="form" name="form" :model="formState" @finish="onSearch">
						<a-row>
							<a-form-item name="mainTitle" label="卖品名称" style="margin-right: 30px;">
								<a-input v-model:value="formState.mainTitle" placeholder="请输入卖品名称"></a-input>
							</a-form-item>

							<a-form-item v-if="id && from != 'addSnack'" name="type" label="卖品类型" style="margin-right: 30px;">
								<a-select v-model:value="formState.type" style="width: 180px;">
									<a-select-option :value="''">全部</a-select-option>
									<a-select-option :value="1">普通卖品</a-select-option>
									<a-select-option :value="5">套餐卖品</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item v-if="id" name="isShelf" label="状态" style="margin-right: 30px;">
								<a-select v-model:value="formState.isShelf" style="width: 180px;">
									<a-select-option :value="''">全部</a-select-option>
									<a-select-option :value="1">已上架</a-select-option>
									<a-select-option :value="0">已下架</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item v-if="!id" name="isDisabled" label="状态" style="margin-right: 30px;">
								<a-select v-model:value="formState.isDisabled" style="width: 180px;">
									<a-select-option :value="''">全部</a-select-option>
									<a-select-option :value="0">已启用</a-select-option>
									<a-select-option :value="1">已禁用</a-select-option>
								</a-select>
							</a-form-item>

							<a-form-item name="classifyId" label="卖品分类">
								<div style="width: 190px;">
									<a-cascader
									    v-model:value="formState.classifyId"
									    :options="classifyList"
										:fieldNames="{
											label: 'title',
											value: 'id',
											children: 'children'
										}"
									    placeholder="请选择卖品分类"
									  />
								</div>
							</a-form-item>
						</a-row>

						<a-row>
							<a-col :span="18"></a-col>
							<a-col :span="6" style="text-align: right;">
								<a-button type="primary" html-type="submit">搜索</a-button>
								<a-button style="margin-left: 20px;" @click="reset">重置</a-button>
							</a-col>
						</a-row>
					</a-form>
					<div style="margin-top: 10px;max-height: 560px;overflow-y: auto;">
						<a-table rowKey="id" :dataSource="dataList" :pagination="pagination"
							:columns="columns"
							:scroll="{ x: 1300 }"
							:rowSelection="{ type: type, selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, onChange: onChange }"
							bordered size="middle"
						>
							<template #bodyCell="{column, record}">
								<template v-if="column.key === 'image'">
									<a-image style="width: 40px;" :src="record.imgUrl"></a-image>
								</template>
								<template v-if="column.key === 'card'">
									<div v-if="record.isCard">
										<a-tag color="orange">{{ ['次卡', '年卡'][record.cardType - 1] }}</a-tag>
										<div>卡次数：{{ record.cardBalanceTimes }}</div>
										<div>卡等级：{{ record.cardTitle }}</div>
									</div>
									<div v-else>--</div>
								</template>
								<template v-if="column.key === 'coupon'">
									<div v-if="record.isCoupon">
										<div v-if="record.ticketBatchNum">
											<div>兑换券批次号：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatch?.batchNo }}</span></div>
											兑换券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.ticketBatchNum }}张</span>
										</div>
										<div v-if="record.snackBatchNum">
											<div>卖品券批次号：<span style="color: rgb(102, 102, 102);">{{ record.snackBatch?.batchNo }}</span></div>
											卖品券发放数量：<span style="color: rgb(102, 102, 102);">{{ record.snackBatchNum }}张</span>
										</div>
									</div>
									<span v-else>--</span>
								</template>
								<template v-if="column.key === 'price'">
									<div>销售价：{{ record.nowPrice }}</div>
									<div>成本价：{{ record.costPrice }}</div>
									<div>市场价：{{ record.originPrice }}</div>
								</template>
								<template v-if="column.key === 'type'">
									<a-tag v-if="record.type === 1" color="blue">普通卖品</a-tag>
									<a-tag v-if="record.type === 5" color="green">套餐卖品</a-tag>
								</template>
								<template v-if="column.key === 'isDisabled'">
									<div v-if="id">
										<span v-if="record.isShelf">已上架</span>
										<span v-else>已下架</span>
									</div>
									<div v-else>
										<span v-if="record.isDisabled === 0">已启用</span>
										<span v-else>已禁用</span>
									</div>
								</template>
							</template>
						</a-table>
					</div>
				</a-spin>
			</a-modal>
		</a-spin>
	</span>
</template>

<script>
	import {
		getGoodsAllClassifyList
	} from '@/service/modules/mall.js';
	import { getGoodsList, getStoreGoodsList } from '@/service/modules/goods.js';
	export default {
		model: {
			prop: 'value'
		},
		props: {
			value: {
				type: Array,
				default: () => {
					return []
				}
			},
			selectItem: {
				type: Array,
				default: () => {
				    return []
				}
			},
			id: {	//影院ID
				type: Number,
				default: 0
			},
			type: {
				type: String,
				default: 'checkbox'
			},
			searchMap: { // 外部传入搜索数据
				type: Object,
				default: () => {}
			},
      from: {
        type: String,
        default: ''
      }
		},
		data() {
			return {
				loading: false,
				showSnackModal: false,
				classifyList: [],
				formState: {
					type: '',
					isDisabled: '',
					isShelf: '',
				},
				searchData: {},
				selectedRowKeys: [],
				selectedListData:[],
				dataList: [],
				columns: [{
					title: '卖品名称',
					dataIndex: 'mainTitle'
				}, {
					title: '卖品图片',
					key: 'image',
					width: 80
				}, {
					title: '剩余库存',
					dataIndex: 'stock',
					width: 100
				}, {
					title:'价格信息',
					key: 'price',
					width: 120
				}, {
					title: '卖品类型',
					key: 'type',
					width: 100
				}, {
					title: '卡信息',
					key: 'card'
				}, {
					title: '券信息',
					key: 'coupon'
				}, {
					title: '状态',
					key: 'isDisabled',
					width: 100
				}, {
					title: '卖品分类',
					dataIndex: 'classifyName'
				}],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						if (this.id) {
							this.getStoreData();
						} else {
							this.getData();
						}
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						if (this.id) {
							this.getStoreData();
						} else {
							this.getData();
						}
					}
				},
			}
		},
		watch: {
			selectItem: {
				handler(newVal) {
					this.selectedListData = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			},
			value: {
				handler(newVal) {
					this.selectedRowKeys = JSON.parse(JSON.stringify(newVal));
				},
				deep: true,
				immediate: true
			}
		},
		created(){
			if (!this.id) {
				//  选择卖品库没有价格
				this.columns.splice(5);
			}
			this.getAllClassify();
		},
		methods: {
			onShowModal() {
        if (!this.id) {
          this.$message.error('请先选择影院');
          return
        }
				if (this.id) {
					this.getStoreData();
				} else {
					this.getData();
				}
				this.showSnackModal = true;
				this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				// this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = JSON.parse(JSON.stringify(this.formState));
				if (this.searchData.classifyId && this.searchData.classifyId.length) {
					this.searchData.classifyId = this.searchData.classifyId[this.searchData.classifyId.length - 1];
				} else {
					this.searchData.classifyId = undefined;
				}
				if (this.id) {
					this.getStoreData();
				} else {
					this.getData();
				}
			},
			reset() {
				this.$refs.form.resetFields();
				this.onSearch();
			},
			async getData() {
				this.searchData.type = 1;
				this.loading = true;
				try {
					let ret = await getGoodsList({
						page: this.pagination.current,
						pageSize: this.pagination.pageSize,
						saleType: 2,
						...this.searchData,
						type: 1,
					})
					this.loading = false;
					if (ret.code === 200) {
						this.dataList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					//console.log(e);
					this.loading = false;
				}
			},
			async getStoreData() {
				this.loading = true;
				try {
          const postData = {
            page: this.pagination.current,
            pageSize: this.pagination.pageSize,
            cinemaId: this.id,
            saleType: 2,
            ...this.searchData,
            ...this.searchMap,
          };
          if (this.from == 'addSnack') {
            postData.type = 1;
          }
					let ret = await getStoreGoodsList(postData)
					this.loading = false;
					if (ret.code === 200) {
						this.dataList = ret.data.list;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					//console.log(e);
					this.loading = false;
				}
			},
			async getAllClassify(){
				let ret = await getGoodsAllClassifyList({
					classifyType: 2
				});
				if (ret.code === 200) {
					let classifyList = ret.data.filter(item => {
						item.children = ret.data.filter(subItem => {
							return subItem.parentId === item.id;
						});
						return !item.parentId
					});
					this.classifyList = classifyList;
				}
			},
			onSelectChange(record, selected) {
				if (this.type === 'checkbox') {
					if (selected) {
						this.selectedListData.push(JSON.parse(JSON.stringify(record)));
						this.selectedRowKeys.push(record.id);
					} else {
						this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(record.id), 1);
						this.selectedListData = this.selectedListData.filter(item => {
							return item.id !== record.id;
						});
					}
				} else {
					if (selected) {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [record.id];
					} else {
						this.selectedListData = [JSON.parse(JSON.stringify(record))];
						this.selectedRowKeys = [];
					}
				}
			},
			onSelectAll(selected) {
				if (selected) {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) === -1) {
							this.selectedListData.push(JSON.parse(JSON.stringify(item)));
							this.selectedRowKeys.push(item.id);
						}
					})
				} else {
					this.dataList.forEach(item => {
						if (this.selectedRowKeys.indexOf(item.id) !== -1) {
							this.selectedListData.splice(this.selectedRowKeys.indexOf(item.id), 1);
							this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(item.id), 1);
						}
					})
				}
			},
			onChange(key) {

			},
			onCancel() {
			    this.showSnackModal = false;
			    this.selectedRowKeys = JSON.parse(JSON.stringify(this.value));
				this.selectedListData = JSON.parse(JSON.stringify(this.selectItem));
			},
			onOK() {
				this.$emit('update:value', this.selectedRowKeys);
				this.$emit('change', this.selectedListData);
				this.showSnackModal = false;
			}
		}
	}
</script>

<style>
</style>
